<template>
  <div class="brand-spike">
  <div v-html="aboutHtml" class="page"></div>
  </div>
</template>

<script setup>
import {getProductdetails} from '@/api/productdetails.js'
import { onMounted ,ref} from 'vue';
import { useRoute } from 'vue-router';
import baseURL from "@/utils/config";
const route = useRoute();
const aboutHtml = ref('');
const appendDomainToImgSrc=(htmlString, domain)=> {
    // 正则表达式匹配<img>标签的src属性
    const imgSrcPattern = /<img\s+[^>]*src\s*=\s*["']([^"']+)["'][^>]*>/g;
    
    // 回调函数，用于替换匹配到的src值
    function replaceSrc(match, src) {
        // 拼接域名和原src路径
        const newSrc = domain + src;
        // 重新构造<img>标签，确保其他属性不变
        return match.replace(src, newSrc);
    }
    
    // 使用正则表达式替换所有匹配到的src值
    const resultHtml = htmlString.replace(imgSrcPattern, replaceSrc);
    
    return resultHtml;
}
onMounted(async () => {
const langEnv=route.query.langEnv
const id=route.query.id
const res= await getProductdetails({id:id},langEnv)
aboutHtml.value = appendDomainToImgSrc(res.data.page,'https://image.e-rith.com')
})
</script>

<style scoped lang="scss">

.brand-spike{
  background-color: #fff;
}
.brand-spike .page>*{
 width: 100%  !important;
}
.page{
  padding: 0 12px;

}

</style>
